// @import "../../node_modules/ag-grid-community/src/styles/ag-grid";
// @import "../../node_modules/ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin";
@import "../../node_modules/ag-grid-enterprise/dist/styles/ag-grid";
@import "../../node_modules/ag-grid-enterprise/dist/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin";

.ag-theme-alpine {
  font-family: "Open Sans", sans-serif !important;
  --ag-foreground-color: #6b7172;
  --ag-header-foreground-color: var(--ag-foreground-color);
  @include ag-theme-alpine(
    (
      odd-row-background-color: #f8f8f8,
      row-hover-color: null,
      alpine-active-color: #f8f8f8,
      range-selection-border-color: #7dbdc4,
    )
  );
}

// .table thead th {
//   color: rgb(107, 113, 114);
//   font-weight: 700;
//   font-size: 13px;
//   vertical-align: middle;
//   background-color: #f8f8f8;
// }

// .table {
//   border: 1px solid #dee2e6;
// }

// .table thead {
//   background-color: #f8f8f8;
// }

// .table tbody tr {
//   background-color: #f8f8f8 !important;
// }
// .table-striped tbody tr:nth-of-type(odd) {
//   background-color: white !important;
// }
// .row > [class^="col"] {
//   margin-bottom: 15px;
// }

// .keysys-action-bar > [class^="col"] {
//   padding-right: 5px;
//   padding-left: 5px;
// }
.ag-cell {
  min-width: 70px;
  text-align: unset !important;
  text-overflow: ellipsis;
  overflow: hidden;

  span {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  div {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.ag-cell[col-id="id"] {
  padding: 0 !important;
  justify-content: center;
  width: 45px !important;
  min-width: unset !important;
}

.ag-header-cell[col-id="id"] {
  width: 45px !important;
  min-width: unset !important;
}

.ag-cell[col-id="id"] > div {
  display: flex;
  align-items: center;
}

.ag-header-cell {
  padding-left: 16px !important;
  padding-right: 0 !important;
  min-width: 70px;
}

.ag-header-cell-text {
  margin-right: auto;
}

.ag-header-cell-menu-button {
  padding-right: 16px !important;
}

.ag-header-cell-menu-button:hover {
  color: #7dbdc4 !important;
}

.ag-icon.ag-icon-menu:hover {
  color: #7dbdc4;
}

.ag-root-wrapper-body.ag-layout-normal {
  height: 100% !important;
  min-height: 100% !important;
}

.users-grid-container,
.tenants-grid-container {
  margin: 25px 0;
}

//Opportunities grid specific style
.opportunities-grid-container {
  .ag-cell[col-id="amount"],
  .ag-cell[col-id="units"] {
    justify-content: flex-end;
  }
}

//Opportunity Details grid specific style
.opportunity-details-grid-container {
  .ag-cell[col-id="amount"],
  .ag-cell[col-id="units"] {
    justify-content: flex-end;
  }
}

//Tasks grid specific style
.tasks-grid-container {
  .ag-cell[col-id="priority"] {
    word-break: unset;
  }

  .ag-cell[col-id="isComplete"] {
    line-height: 40px !important;
    padding-right: 3px !important;
    padding-left: 3px !important;
  }

  .ag-cell[col-id="isComplete"] > .ag-react-container {
    display: flex;
    align-items: center;
  }
}

//Users grid specific style
.users-grid-container {
  .ag-cell[col-id="accounts"],
  .ag-cell[col-id="opportunities"],
  .ag-cell[col-id="contacts"] {
    justify-content: flex-end;
  }
}

.high-priority {
  position: absolute;
  height: calc(100% - 16px);
  background: rgba(255, 0, 61, 0.26);
  border: 1px solid #bb0606 !important;
  border-radius: 3px;
  margin: 8px 0;
}

.normal-priority {
  position: absolute;
  height: calc(100% - 16px);
  background: #fff172;
  border: 1px solid #ffb800 !important;
  border-radius: 3px;
  margin: 8px 0;
}

.low-priority {
  position: absolute;
  height: calc(100% - 16px);
  background: #ffdbb8;
  border: 1px solid #ffa343 !important;
  border-radius: 3px;
  margin: 8px 0;
}

.checkbox-round {
  width: 1.3em;
  height: 1.3em;
  background-color: #ffffff;
  border-radius: 50%;
  vertical-align: middle;
  border: 2px solid #ddd;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

.checkbox-round:checked {
  border: 2px solid #35cd00;
}

.checkbox-round:checked::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: rotate(45deg) translateY(-75%);
  left: 6px;
  width: 4px;
  height: 8px;
  border: solid #35cd00;
  border-width: 0 2px 2px 0;
}

#benchmarkedCheckbox:checked::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: rotate(45deg) translateY(-75%);
  left: 19px;
  width: 4px;
  height: 8px;
  border: solid #35cd00;
  border-width: 0 2px 2px 0;
}

.ag-header-cell-label {
  justify-content: center;
}

.grid-link {
  color: var(--body-color);
  text-decoration: underline;
}

.grid-link:hover {
  text-decoration: underline;
  color: var(--primary);
}

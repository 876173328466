@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght%40300&display=swap");

$primary: #396d9a;
$secondary: #8abfea;
$success: #80b758;
$info: #009cbe;
$warning: #ff9b42;
$danger: #dc3545;
$light: #eeeeee;
$dark: #5a585a;
$footer: #bdbdbd;
$body-color: #000;
$font-family-base: Roboto, "Open Sans", Arial, sans-serif;

@import "../../node_modules/bootstrap/scss/bootstrap";

@import "login";
@import "header";
@import "sidebar";
@import "grid";
@import "modal";
@import "breadcrumb";
@import "valueWithTitleBox";
@import "keysysAccordion";
@import "progressBar";
@import "keysysSelect";
@import "footer";
@import "keysysForm";
@import "appSettings";

* {
  font-family: Roboto, "Open Sans", Arial, sans-serif;
}

html,
body {
  margin: 0;
  padding: 0;
  background-color: #eff6fa;
  font-weight: 400;
  font-family: $font-family-base !important;
}

html,
body,
#root,
.App {
  height: calc(100vh - 135px); /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100 - 135px) !important;
}

.main-content {
  margin-top: 86px;
  padding-top: 5px;
  padding-bottom: 10px;
  min-height: calc(100vh - 90px);
  display: flex;
}

.row {
  max-width: 100%;
  margin: 0 auto;
}
.padding {
  padding: 0.5rem 1rem;
}
.margin {
  margin: 1rem;
}
.padding-bottom-0 {
  padding-bottom: 0 !important;
}
.padding-top-1 {
  padding-top: 1rem;
}
.padding-left-1 {
  padding-left: 1rem;
}
.form-label {
  font-weight: 700;
  margin: 0;
}

.wide-button {
  min-width: 250px;
}

.clickable {
  cursor: pointer;
}

h1 {
  font-weight: 700;
  font-size: 30px;
}

h2 {
  font-weight: 700;
  font-size: 16px;
  padding: 0 15px;
}
h3 {
  font-weight: 700;
  font-size: 16px;
  padding: 0 15px;
  color: #5a585a;
  padding-top: 1.3rem;
}
h4 {
  font-size: 1rem;
  font-weight: bold;
}
a {
  font-weight: bold;
}

//40 pixels = sidebar width
//135 pixels = breadcrumb + header height
.content-container {
  margin-left: 40px;
  margin-top: 100px;
  min-height: 300px;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

td,
tr {
  vertical-align: middle;
}

.draggable {
  &:active {
    cursor: grabbing;
  }
}

.btn-primary,
.btn-primary:focus,
.btn-primary:hover,
.btn-primary:disabled,
.btn-danger,
.btn-danger:focus,
.btn-danger:hover,
.btn-danger:disabled,
.btn-success,
.btn-success:focus,
.btn-success:hover,
.btn-success:disabled,
.btn-secondary,
.btn-secondary:focus,
.btn-secondary:hover,
.btn-secondary:disabled,
.btn-info,
.btn-info:focus,
.btn-info:hover,
.btn-info:disabled {
  color: #fff;
}

.bg-success-faded {
  background-color: rgba(var(--bs-success-rgb), 0.1);
}

.bg-primary-faded {
  background-color: rgba(var(--bs-primary-rgb), 0.1);
}

.bg-secondary-faded {
  background-color: rgba(var(--bs-secondary-rgb), 0.1);
}

.bg-info-faded {
  background-color: rgba(var(--bs-info-rgb), 0.1);
}

.bg-warning-faded {
  background-color: rgba(var(--bs-warning-rgb), 0.1);
}

.bg-danger-faded {
  background-color: rgba(var(--bs-danger-rgb), 0.1);
}
.bg-white {
  background-color: #fff;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
@media (max-width: 768px) {
  .main-content {
    margin-top: 70px;
  }
}

.chart-width {
  min-width: 100%;
}

.background-grey {
  background-color: #f0f0f0;
  border-radius: 10px;
}

.chart-divider {
  display: inline-block;
  width: 2px;
  background-color: "#EAEAEA";
  margin: 0 10px;
  height: 10em;
}

.label-top {
  transform: translateY(-5px);
}
.label-bottom {
  transform: translateY(15px);
}

.chart-border {
  border-radius: 10px;
  background-color: #fff;
  border: 2px solid #d2d2d2;
  padding: 10px;
}

.mcs-blue-chart-border {
  background-color: #f2f4fd;
}

.mcs-red-chart-border {
  border: 2px rgba(152, 0, 0, 0.47);
  background-color: rgba(152, 0, 0, 0.47);
}

.dropzone-container {
  background: #eee;
  padding: 2rem;
  border: #6b6b6b dashed 1px;
  border-radius: 8px;
  .dropzone-placeholder {
    color: #6b6b6b;
    text-align: center;
    margin-bottom: 0;
  }
}
.ag-body-viewport {
  height: 100px;
}
.mcs-chart-container {
  .mcs {
    border-radius: 0;
    margin-left: 10px;
    padding: 15px;
  }
  .mcs-value {
    font-weight: bold;
  }
  .mcs-value-title {
    font-size: 11px;
    padding: 10px 20px;
  }
  .mcs-red-chart-border {
    border-radius: 5px;
    margin-top: 10px;
    .mcs-value {
      color: #fff;
    }
    .mcs-value-title {
      font-weight: bold;
    }
  }
}

.fa-arrow-circle-up {
  font-size: 20px;
  color: #fff;
  background-color: #00eb5e;
  border: 2px solid #00eb5e;
  border-radius: 20px;
}

.fa-arrow-circle-down {
  font-size: 20px;
  color: #fff;
  background-color: #980000;
  border: 2px solid #980000;
  border-radius: 20px;
}

.fa-circle {
  &.blue {
    color: blue;
  }
  &.red {
    color: red;
  }
}

.fa-dollar-sign {
  font-size: 10px;
  font-weight: normal;
  vertical-align: top;
  color: #aaa;
}

.blue {
  background-color: #8abfea;
  color: #3c709d;
  font-weight: bold;
}
.no-underline {
  text-decoration: none;
}
.btn {
  background-color: #8abfea;
  color: #3c709d;
  border: 0;
  padding: 10px;
  font-weight: bold;
  display: block;
  margin-left: 5px;
  margin-right: 5px;
}
.update-btn {
  background-color: #fff;
  border: 1px solid #8abfea;
  i.update {
    font-size: 20px;
    color: #fff;
    background-color: #111;
    border: 2px solid #111;
    border-radius: 20px;
    float: right;
  }
}

.padding-0 {
  padding: 0;
}
.padding-half {
  padding: 0.5rem;
}
.padding-1 {
  padding: 1rem;
}
.margin-half {
  margin: 0.5rem;
}
.margin-1 {
  margin: 1rem;
  margin-top: 30px;
}
.margin-bottom-0 {
  margin-bottom: 0 !important;
}
.margin-bottom-half {
  margin-bottom: 0.5rem;
}
.margin-bottom-1 {
  margin-bottom: 1rem;
}
.recharts-pie-label-text {
  font-size: 12px;
}

.no-bg {
  background: none !important;
  border: none !important;
}

.performing-facilities {
  th:nth-child(-n + 2) {
    text-align: right;
  }
  tr td:nth-child(-n + 2) {
    text-align: right;
  }
}

.no-bullet-list {
  list-style: none;
}

.filter-margin {
  margin-top: 25px;
}

.no-data {
  margin: 85px;
  color: #5f5f5b;
}

.display-linebreak {
  white-space: pre-line;
}

.currency-align {
  text-align: right;
}

.change-align {
  text-align: center;
}

.chart-margin {
  margin-left: 60px;
}

.view-table-text {
  text-align: center;
}

.custom-tooltip {
  padding: 10px;
  box-shadow: 1px 1px 1px 1px #c4c4c4;
  border-radius: 5px;
  background-color: #ffffff;
  color: #257bc1;
  font-weight: bold;
}

.error-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.edit-file-info {
  width: 97%;
  margin-top: 10px;
}

.panel-body {
  height: 355px;
  overflow-x: auto;
}

.access-denied-message {
  background-color: #f8d7da;
  color: #842029;
  border: 1px solid #f5c2c7;
  border-radius: 4px;
  padding: 20px;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.access-denied- i {
  font-size: 24px;
  margin-bottom: 10px;
}

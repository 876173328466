.keysys-header {
  background-color: $primary;
  height: 86px;
  z-index: 1054;
  top: 0;
  width: 100%;
  position: fixed;
}

.box-shadow {
  box-shadow: 0 4px 4px #66666640;
}

.header-item {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
}

nav.navbar {
  height: 86px;
}

.nav-link {
  color: #fff !important;
  font-size: 20px;
  font-weight: 700;
  padding-left: 20px !important;
}

.add-shadow {
  box-shadow: rgba(60, 64, 67, 0.3) 0 1px 2px 0;
}

.nav-link {
  border-bottom: 0 !important;
}

.navbar-text {
  width: 100%;
  font-size: 37px;
  font-weight: bold;
  color: var(--body-color) !important;
}

.header-dropdown {
  margin-right: 10px;
}

#headerButton {
  font-size: 16px;
  margin-right: 0;
}

#shadow {
  background-color: transparent;
  position: fixed;
  width: calc(100% - 40px);
  left: 40px;
  top: 85px;
  z-index: 1;
}

#shrunkenShadow {
  height: unset;
  font-size: 1rem;
  background-color: transparent;
  position: fixed;
  width: calc(100% - 40px);
  left: 40px;
  top: 85px;
  z-index: 1;
}

#shrinkTitle50 {
  font-size: 24px !important;
}

#shrinkTitle75 {
  font-size: 20px !important;
}

@media (max-width: 992px) {
  .navbar-text {
    font-size: 5vw !important;
    display: block;
  }

  .navbar-collapse {
    background-color: #fff;
  }

  #shrinkTitle50 {
    font-size: 16px !important;
  }

  #shrinkTitle75 {
    font-size: 14px !important;
  }
}

@media (max-width: 768px) {
  nav.navbar .dropdown-column {
    padding: 0;
  }

  nav.navbar {
    height: 70px;
  }

  .keysys-header {
    background-color: #fff;
    height: 70px;
  }

  .header-dropdown {
    margin-right: 0;
    margin-top: 2px;
  }
}

@media (max-width: 450px) {
  .header-dropdown {
    font-size: 14px;
  }

  #shrinkTitle50 {
    font-size: 14px !important;
  }

  #shrinkTitle75 {
    font-size: 12px !important;
  }

  #shadow {
    height: unset;
    font-size: 3vw;
  }

  #shrunkenShadow {
    height: unset;
    font-size: 0.5rem;
    background-color: transparent;
    position: fixed;
    width: calc(100% - 40px);
    left: 40px;
    top: 85px;
    z-index: 1;
  }
}

.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none;
}

.count {
  border: 2px solid #ffffff;
  border-radius: 20px;
  box-sizing: border-box;
  color: #ffffff;
  padding: 3px 7px;
  margin-left: 10px;
}

.accordion-header {
  display: flex;
  align-items: center;
  // background-color: #7dbdc4;
  color: #ffffff;
  font-weight: bold;
  // padding: 5px 20px

  .import-accordion-button {
    background-color: #079046;
    color: white;
    // margin-bottom: 15px;
    // align-self: right;
    // text-align: right;
    margin-left: 10px;
  }

  .import-grid-button {
    background-color: #079046;
    color: white;
    // margin-bottom: 15px;
    align-self: right;
    text-align: right;
    margin-left: 12px;
  }

  span[data-testid="accordion-title"] {
    cursor: pointer;
  }
  button {
    background-color: #0e63ac !important;
    color: white !important;
  }
  .accordion-button::after {
    filter: brightness(0) invert(1);
  }
  .accordion-button:focus {
    border-color: #0e63ac !important;
    box-shadow: 0 0 0 0.25rem #0e63ac;
  }
  .accordion-button > .container {
    margin-left: unset;
  }
}

.fa-chevron-down,
.fa-chevron-up {
  color: white;
}

.header-end {
  text-align: right;
  display: flex;

  .help-text-link {
    margin-right: 10px;
  }

  .fa-plus-circle,
  .fa-question-circle {
    font-size: 1.5em;
    color: #ffffff;
  }
}

.fa-question-circle {
  color: #62b2b8;
}

.fa-plus-circle {
  cursor: pointer;
}

.menu-preview {
  .menu-preview-item {
    height: 50px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 350px;
  }
}

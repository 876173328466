.sidebar-root-under {
  position: fixed !important;
  z-index: 0;
  width: 40px;
}
.select-sidebar {
  min-height: 100%;
}
.sidebar-root-above {
  position: fixed !important;
  z-index: 9999;
}

/**************************
*      Menu Content       *
**************************/
.sidebar-menu {
  display: flex;
  height: calc(var(--vh, 1vh) * 100) !important;
  width: 22.5vw;
  .document-exchange-count-badge {
    background-color: #204ea0 !important;
  }
  .sidebar-icon-container {
    &.expanded {
      width: 50px;
    }
  }
  .menu-item-list {
    list-style-type: none;
    margin: 20px auto 20px auto;
    font-size: 1rem;
    padding-inline-start: 0;
  }
  .fas.fa-layer-group {
    color: #d4e4e6;
    display: inline;
  }
  .far.fa-flag {
    color: #d4e4e6;
    display: inline;
  }
  .fas.fa-home {
    color: #d4e4e6;
    display: inline;
  }
  .fa-envelope {
    color: #d4e4e6;
    display: inline;
  }
  .far.fa-user-circle {
    color: #d4e4e6;
    display: inline;
  }
  .no-underline {
    text-decoration-line: none;
  }
  .fas.fa-plus-circle {
    display: inline;
    color: #d4e4e6;
    cursor: pointer;
    transition: 0.4s ease-in-out;
    font-size: 1.5rem;
  }
  .fas.fa-plus-circle:hover {
    cursor: pointer;
    transition: 0.4s ease-in-out;
    opacity: 0.7;
  }
  .sidebar-image-icon {
    width: 2rem;
    height: auto;
  }
  .sidebar-menu-item-content {
    color: #d4e4e6;
    width: 100%;
    display: inline-block;
    font-size: 1rem;
    transition: 0.4s ease-in-out;
  }
  .sidebar-menu-item-content:hover {
    color: #d4e4e6;
    text-decoration: none;
    transition: 0.4s ease-in-out;
    opacity: 0.7;
  }
  .sidebar-menu-item {
    padding: 10px;
    display: flex;
    align-items: center;
  }
  .sidebar-menu-arrow-button {
    display: flex;
    position: absolute;
    bottom: 10px;
    right: 5px;
    justify-content: center;
    align-content: center;
    background-color: transparent; //#079046;
    border: none;
    transition: 0.4s ease-in-out;
  }
  .sidebar-menu-logout-button {
    color: #d4e4e6;
    display: flex;
    position: absolute;
    bottom: 10px;
    left: 5px;
    justify-content: center;
    align-items: center;
    background-color: transparent; // #079046;
    border: none;
    transition: 0.4s ease-in-out;
    font-size: 1.25rem;
    .logout-icon {
      padding-right: 10px;
    }
  }
  .sidebar-menu-logout-button:hover {
    opacity: 0.7;
    transition: 0.4s ease-in-out;
  }
  .sidebar-menu-logout-button:active {
    color: #d4e4e6 !important;
    background-color: transparent !important; // #079046 !important;
    border: none !important;
  }
  .sidebar-menu-logout-button:focus {
    box-shadow: none !important;
  }
  .sidebar-menu-arrow-button:hover {
    background-color: transparent; //#079046;
    transition: 0.4s ease-in-out;
    opacity: 0.7;
  }
  [alt="Encircled arrow facing left"] {
    width: 2rem;
    height: auto;
  }
}

@media (min-width: 1140px) and (max-width: 1600px) {
  .sidebar-menu {
    .menu-item-list {
      margin: 20px auto 0 auto !important;
    }
    .sidebar-menu-item {
      padding: 10px;
    }
    .sidebar-menu-item-content {
      margin: 5px 10px !important;
      font-size: 1rem !important;
    }
  }
}

@media (width: 1024px) {
  .sidebar-menu {
    width: 33.33vw !important;
  }
}

@media (max-width: 992px) {
  .sidebar-menu {
    width: 100vw !important;
  }
}

@media (width: 768px) {
  .sidebar-menu {
    width: 50vw !important;
  }
}

@media (max-height: 450px) {
  .sidebar-menu {
    width: 33.33vw !important;
    .menu-item-list {
      margin: 0 auto !important;
    }
    .sidebar-menu-item {
      padding: 10px;
    }
    .sidebar-menu-item-content {
      margin: 5px 10px !important;
      font-size: 1rem !important;
    }
    .sidebar-menu-logout-button {
      font-size: 0.75rem !important;
      .logout-icon {
        width: 1.5rem;
        height: auto;
      }
    }
    [alt="Encircled arrow facing left"] {
      width: 1rem !important;
      height: auto !important;
    }
  }
}

/**************************
*      Dock Content       *
**************************/

.sidebar-dock {
  width: 40px;
  overflow-y: unset !important;
  .dock-container {
    position: absolute;
    height: calc(var(--vh, 1vh) * 100) !important;
    width: 40px;
    border-right: 1px solid #eceef8;
    .collapsed-content {
      position: absolute;
      top: 75px;
      right: 50%;
      width: 100%;
      transform: translateX(50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      ul {
        list-style-type: none;
        margin: 20% auto 20% auto;
        font-size: 1rem;
        padding-inline-start: 0;
        li {
          padding: 10px 0;
          margin: 5px 0;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .sidebar-dock-hamburger-button {
      position: absolute;
      top: 25px;
      right: 50%;
      transform: translateX(50%);
      width: 20px;
      height: auto;
      background-color: transparent; // #079247;
      display: flex;
      justify-content: center;
      align-content: center;
      border: none;
      transition: 0.4s ease-in-out;
    }
    .sidebar-dock-hamburger-button:hover {
      background-color: transparent; //#079247;
      transition: 0.4s ease-in-out;
      opacity: 0.7;
    }
    .sidebar-dock-hamburger-button:active {
      background-color: #ffffff !important;
      border: none !important;
    }
    .sidebar-dock-hamburger-button:focus {
      background-color: #ffffff !important;
      border: none !important;
      box-shadow: none !important;
    }
    .fas.fa-bars {
      font-size: 24px;
    }
    .sidebar-dock-arrow-button {
      position: absolute;
      bottom: 15px;
      right: 50%;
      transform: translateX(50%);
      width: 20px;
      height: auto;
      background-color: transparent; //#079247;
      display: flex;
      justify-content: center;
      align-content: center;
      border: none;
    }
    .sidebar-dock-arrow-button:hover {
      background-color: transparent; //#079247;
    }
    .sidebar-dock-arrow-button:active {
      background-color: transparent !important; //#079247 !important;
      border: none !important;
    }
    .sidebar-dock-arrow-button:focus {
      background-color: transparent !important; // #079247 !important;
      border: none !important;
      box-shadow: none !important;
    }
    [alt="Encircled arrow facing right"] {
      transition: 0.4s ease-in-out;
    }
    [alt="Encircled arrow facing right"]:hover {
      filter: contrast(50%);
      transition: 0.4s ease-in-out;
    }
  }
}
